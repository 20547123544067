import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, from, Observable, of, Subject } from 'rxjs';
import { PaymentService } from './payment.service';
import { environment } from '@env/environment';
import { Payment, PaymentResult } from '@app/models/payments';
import { AppConstantsService } from './app-constants.service';
import { HttpService } from './http.service';
import { v4 as uuidv4 } from 'uuid';
import { ThemeService, Mode } from './theme.service';

declare var Stripe: any;

const PENCE_IN_POUND: number = 100;
const TOP_UP_LABEL: string = 'ISA Top Up';
const PAYMENT_COUNTRY_CODE = 'GB';
const PAYMENT_CURRENCY_CODE = 'gbp';

export type WalletOptions = {
  applePay: boolean;
  googlePay: boolean;
  link: boolean;
};

// extension declarations for running in WebView under Android
declare function interop_android(): boolean;
declare function invoke_DoCheckGooglePay(data: string): any;
declare function invoke_DoExecuteGooglePay(data: string): any;

interface NativeGooglePayCheck {
  merchantName?: string;
  apiKey?: string;
}

interface NativeGooglePayExecute {
  merchantName?: string;
  apiKey?: string;
  total?: number;
  currency?: string;
  country?: string;
}

@Injectable()
export class StripeService {
  public readonly api = {
    PAYMENT_INTENT: (id, amount) =>
      `${this.configuration.server}/api/payments/initpi/${id}/${amount}`,
  };

  public cardElement: any;
  public readonly paymentRequestButtonId = '#payment-request-button';
  public readonly walletPayment$ = new BehaviorSubject<'success' | 'fail' | null>(null);

  private stripe: any;
  private paymentRequest: any;
  private paymentAmountInPounds: number = 100;
  private paymentModel: Partial<Payment>;

  private paymentIntentClientSecret: string;

  private readonly _walletOptions = new Subject<WalletOptions>();
  readonly walletOptions$ = this._walletOptions.asObservable();

  private readonly _nativeGooglePay = new Subject<boolean>();
  readonly nativeGooglePay$ = this._nativeGooglePay.asObservable();

  private paymentRequestFn: any;

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService,
    private paymentService: PaymentService,
    private themeService: ThemeService
  ) {}

  /**
   * Initialises stripe with current environment API key
   */
  public initialiseStripe(): void {
    this.stripe = Stripe(environment.apiKeys.stripe);
  }

  /**
   * Returns reference to stripes web elements API
   */
  public getElements(): any {
    return this.stripe.elements();
  }

  /**
   * Updates the payment intent with a new monetary
   */
  public changePaymentRequestAmount(value: number): void {
    this.paymentAmountInPounds = value;

    const amountInMinorUnits = Math.round(value * PENCE_IN_POUND);

    this.paymentRequest.update({
      total: {
        label: TOP_UP_LABEL,
        amount: amountInMinorUnits,
      },
    });
  }

  /**
   * Generates the payment intent
   */
  public getPaymentRequest(): unknown {
    return this.stripe.paymentRequest({
      country: PAYMENT_COUNTRY_CODE,
      currency: PAYMENT_CURRENCY_CODE,
      total: {
        label: TOP_UP_LABEL,
        amount: this.paymentAmountInPounds * PENCE_IN_POUND,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
  }
  /**
   * Setup the payment model initial values
   */
  public initialPaymentModel(payment: Partial<Payment>) {
    this.paymentModel = {
      MC_SFCustomerPlanId: payment.MC_SFCustomerPlanId,
      name: payment.name,
      email: payment.email,
    };
  }

  /**
   * Initialise the payment intent
   */
  private async initialisePayment(): Promise<void> {
    const paymentId = uuidv4();

    var result = this.http.get<string>(
      this.api.PAYMENT_INTENT(paymentId, this.paymentAmountInPounds)
    );

    this.paymentIntentClientSecret = await firstValueFrom(result);
  }

  /**
   * 1. Binds payment intent to the payment request button (wallet pay button) and caches the wallet options available
   * 2. Handles the paymentmethod event and completes the payment journey
   * 3. Pushes success/fail back to walletPayment observable
   */
  public setUpPaymentButton() {
    // check for native support
    if (typeof interop_android === 'function' && interop_android()) {
      // check for native Google Pay support
      this.invoke_native_CheckGooglePay().then((result) => {
        this._nativeGooglePay.next(result);
      });
    }

    this.paymentRequest = this.getPaymentRequest();

    const prButton = this.getElements().create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
      style: {
        paymentRequestButton: {
          theme: this.themeService.mode === Mode.LIGHT ? 'dark' : 'light',
          height: '45px',
        },
      },
    });

    this.paymentRequest.canMakePayment().then((result) => {
      this._walletOptions.next(result);

      if (result) {
        prButton.mount(this.paymentRequestButtonId);
      }
    });

    var paymentMethodFn = async (ev) => {
      await this.initialisePayment();

      const trackPayment = {
        MC_SFCustomerPlanId: this.paymentModel.MC_SFCustomerPlanId,
        paymentIntentId: this.paymentIntentClientSecret,
        amount: this.paymentAmountInPounds,
        name: this.paymentModel.name,
        email: this.paymentModel.email,
        currency: PAYMENT_CURRENCY_CODE,
      };

      const completePayment$ = this.paymentService
        .recordStripeWalletPayment(trackPayment)
        .pipe(tap(() => this.walletPayment$.next('success')));

      this.stripe
        .confirmCardPayment(
          this.paymentIntentClientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )
        .then((confirmResult: { error: any; paymentIntent: { status: string } }) => {
          if (confirmResult.error) {
            ev.complete('fail');
          } else {
            ev.complete('success');

            if (confirmResult.paymentIntent.status === 'requires_action') {
              stripe
                .confirmCardPayment(this.paymentIntentClientSecret)
                .then((confirmResult: { error: any; paymentIntent: { status: string } }) => {
                  if (confirmResult.error) {
                    this.walletPayment$.next('fail');
                  } else {
                    completePayment$.subscribe();
                  }
                });
            } else {
              completePayment$.subscribe();
            }
          }
        });
    };

    // hook for stripe
    this.paymentRequest.on('paymentmethod', paymentMethodFn);

    // hook for native
    this.paymentRequestFn = paymentMethodFn;
  }

  // Reset wallet payment state to avoid future attempts using the previous state
  public resetWalletPaymentState() {
    this.walletPayment$.next(null);
  }

  /**
   * Attempts a card payment (either through standard top up or initial deposit journey)
   * 1. If card payment suceeds, emits result
   * 2. If card payment requires authorisation, 3d secure is invoked
   * 3. Finally a card payment is reattempted after 3d secure has passed
   */
  public takeTopUpPayment(
    paymentModel: Payment,
    isInitialDeposit: boolean = false
  ): Observable<PaymentResult> {
    this.paymentModel = paymentModel;

    return from(this.createPaymentMethod()).pipe(
      switchMap(() =>
        isInitialDeposit
          ? this.paymentService.performTakeDeposit(paymentModel.dataId, paymentModel.transId)
          : this.paymentService.recordStripePayment(paymentModel)
      ),
      switchMap((paymentResult) =>
        paymentResult.status === 'succeeded'
          ? of(paymentResult) // If successful, return the result
          : from(this.load3dSecure(paymentResult)).pipe(
              switchMap(() =>
                isInitialDeposit
                  ? this.paymentService.performTakeDeposit(
                      paymentModel.dataId,
                      paymentModel.paymentIntentId
                    )
                  : this.paymentService.recordStripePayment(paymentModel)
              )
            )
      )
    );
  }

  /**
   * Attempts a card payment and resolves promise
   * 1. If card payment suceeds, emits result
   * 2. If card payment requires authorisation, 3d secure is invoked
   * 3. Finally a card payment is reattempted after 3d secure has passed
   */
  public createPaymentMethod(): Promise<PaymentResult> {
    let paymentResult = new PaymentResult();

    let promise = new Promise<PaymentResult>((resolve, reject) => {
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.paymentModel.cardElement,
          billing_details: {
            name: this.paymentModel.name,
          },
        })
        .then((result) => {
          if (result.error) {
            paymentResult.errorMessage = result.error.message;
            reject(paymentResult);
            return;
          }

          this.paymentModel.transId = result.paymentMethod.id;
          resolve(paymentResult);
        });
    });

    return promise;
  }

  /**
   * Handles 3d secure with stripes built in 3d secure dialog,
   * Resolves the promise with a result of the 3d secure outcome
   */
  private load3dSecure(paymentResult: PaymentResult): Promise<PaymentResult> {
    let promise = new Promise<PaymentResult>((resolve, reject) => {
      if (paymentResult.status !== 'requires_action') {
        resolve(paymentResult);
        return;
      }

      this.stripe.handleCardAction(paymentResult.paymentIntentClientSecret).then((result) => {
        if (result.error) {
          paymentResult.errorMessage = result.error.message;
          reject(paymentResult);
          return;
        }

        this.paymentModel.transId = null;
        this.paymentModel.paymentIntentId = result.paymentIntent.id;
        resolve(paymentResult);
      });
    });

    return promise;
  }

  /**
   * Invoke the native check to see if Google Pay is available
   */
  invoke_native_CheckGooglePay(): Promise<boolean> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneCheckGooglePay = (rv: string) => {
        if (!rv) {
          console.warn(`NativeGooglePay: invoke_DoCheckGooglePay() - failure`);
          reject();
        } else {
          const result = rv.toUpperCase();
          console.info(`NativeGooglePay: Is native google pay available? - ${result}`);
          resolve(result == 'TRUE');
        }
      };

      let options: NativeGooglePayCheck = {
        merchantName: environment.appName,
        apiKey: environment.apiKeys.stripe,
      };

      // obj -> JSON
      let jsonOut = JSON.stringify(options);

      // call native function
      invoke_DoCheckGooglePay(jsonOut);
    });
  }

  /**
   * Invoke the native execution of Google Pay (e.g. bring up the payment sheet in the native UI)
   */
  invoke_native_ExecuteGooglePay(): Promise<boolean> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneExecuteGooglePay = async (rv: string) => {
        if (!rv) {
          console.warn(`NativeGooglePay: invoke_DoExecuteGooglePay() - failure`);
          reject();
        } else if (rv.toUpperCase() == 'ERROR') {
          console.warn(`NativeGooglePay: invoke_DoExecuteGooglePay() - error`);
          resolve(false);
        } else if (rv.toUpperCase() == 'CANCEL') {
          console.warn(`NativeGooglePay: invoke_DoExecuteGooglePay() - user cancelled`);
          resolve(true);
        } else {
          // manually create a stripe 'card' payment method using the Google Pay token
          const obj = await this.stripe.createPaymentMethod({
            type: 'card',
            card: {
              token: rv /* e.g. 'tok_1NfmdqKlaCjU1kQHzYKlrKaW' */,
            },
          });

          // manually submit payment request to stripe
          let ev = {
            paymentMethod: obj.paymentMethod,
            complete: (rv: string) => {
              const result = rv.toUpperCase();
              console.info(`NativeGooglePay: confirmCardPayment() - ${result}`);
              resolve(result == 'SUCCESS');
            },
          };
          await this.paymentRequestFn(ev);
        }
      };

      let options: NativeGooglePayExecute = {
        merchantName: environment.appName,
        apiKey: environment.apiKeys.stripe,
        total: this.paymentAmountInPounds,
        currency: PAYMENT_CURRENCY_CODE,
        country: PAYMENT_COUNTRY_CODE,
      };

      // obj -> JSON
      let jsonOut = JSON.stringify(options);

      // call native function
      invoke_DoExecuteGooglePay(jsonOut);
    });
  }
}
